import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgsSortOrderDirection } from '@syspons/models';

import Parse from 'parse';

/**
 Service to handle parse queries
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export default class ParseQueryService {
  limit: number = 100;

  getClassItems<T extends Parse.Attributes>(
    className: string,
    getAllItems?: boolean,
    orderBy?: string,
    orderDirection?: string,
    skipIndex?: number,
    limit?: number,
  ): Promise<Array<Parse.Object<T>>> {
    return new Promise((resolve, reject) => {
      const performQuery = (limit: number) => {
        const query = new Parse.Query(className).skip(skipIndex || 0).limit(limit);
        if (orderDirection && orderDirection === NgsSortOrderDirection.descending) {
          query.addDescending(orderBy || 'updatedAt');
        } else {
          query.addAscending(orderBy || 'updatedAt');
        }
        query.find().then(res => {
          if (res) {
            resolve(res as Array<Parse.Object<T>>);
          } else {
            resolve([]);
          }
        }, reject);
      };

      if (getAllItems) {
        new Parse.Query(className).count().then(count => {
          performQuery(count);
        }, reject);
      } else {
        performQuery(limit || this.limit);
      }
    });
  }

  getClassObject<T extends Parse.Attributes>(className: string, objectId: string): Promise<Parse.Object<T>> {
    return new Promise((resolve, reject) => {
      if (className && objectId) {
        new Parse.Query<Parse.Object<T>>(className)
          .equalTo('objectId', objectId as any)
          .first()
          .then(res => {
            resolve(res as Parse.Object<T>);
          }, reject);
      } else {
        reject(new Error(className && !objectId ? 'ObjectId is missing' : 'ClassName is missing'));
      }
    });
  }
}
